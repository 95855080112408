<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Website Forms</h1>
      <div class=" flex justify-end items-end">
        <button
          class=" bg-blue-600 py-3 px-6 rounded-md  text-white"
          @click="downloadCsv"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center">
        <input
          type="text"
          class="inline-block  px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
          name=""
          placeholder="Search by name, account number, ID"
          id=""
          v-model="search"
        />
        <button
          class="bg-blue-600 py-3 mx-5 px-6 rounded-md text-xs text-white"
          @click="searchRecords"
        >
          Search Records
        </button>
      </div>
      <div class="mt-14">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="11"
              style="background: #DEE6F2;"
            >
              Contact Forms
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Submission Count
            </th>
          </tr>
          <tr
            class="text-xs cursor-pointer"
            v-for="(form, i) in forms"
            :key="i"
            @click="
              $router.push({
                name: 'ViewWebsiteForms',
                params: {
                  id: form.id,
                },
              })
            "
          >
            <td>{{ form.name }}</td>
            <td>{{ form.submission_count }}</td>
          </tr>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { GET_USER_BUSINESS_DATA } from "../browser-db-config/localStorage";
import authorizedUsers from "../config/authUsers";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      forms: [],
    };
  },
  computed: {
    check_auth() {
      const userEmail = GET_USER_BUSINESS_DATA().email;
      return authorizedUsers.includes(userEmail);
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {},
  created() {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer lAfsbcrVjdVWWRjICgyDBSpM1WJPezS8E1gSHicaGjE"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      this.isLoading = true;
      console.log(this.isLoading);
      fetch(
        "https://api.netlify.com/api/v1/sites/87578c78-f328-4eee-9612-661414f7390c/forms",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          this.forms = res;
          // console.log(result)
        })
        .catch((error) => console.log("error", error));
    } catch (error) {}
    // let response = await this.$store.dispatch("FETCH_FIXED_ACCOUNTS");
    // this.accounts = response.data;
    // if (response.data) {
    this.isLoading = false;
    console.log(this.isLoading);
    // }
  },
  methods: {
    downloadCsv() {
      return;
    },
    searchRecords() {
      return;
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
